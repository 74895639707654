*:focus {
    outline: none;
}

.singleItemTitleBtnCont {
    width: calc(100% - 20px);
    display: flex;
    align-items: center;
    padding-right: 45px;
    position: relative;
}

.singleItemTitleBtnCont img {
    width: auto;
    height: 15px;
    margin-left: 2px;
    margin-right: 5px;
}

.singleItemTitleBtnCont form {
    width: 100%;
}

.workItemRow input {
    font-size: 14px;
    font-weight: 400;
    padding: 3px 5px;
    padding-top: 4px;
    border: none;
    outline: none;
}

.workItemRow .MuiInputBase-root{
    padding: 2px !important;
    border-radius: 0px !important;
}

.subheadStyle input {
    font-size: 14px;
    font-weight: 400;
    padding: 3px 5px;
    padding-top: 4px;
    padding-right: 0px;
    border: none;
    outline: none;
}

.subheadStyle .editContIcon {
    display: none;
}

.subheadStyle:hover .editContIcon {
    display: flex;
    position: absolute;
    top: 2px;
    left: 2px;
    font-size: 15px;
    color: #2196f3;
}

.resourceLine {
    position: relative;
}

.resourceLine .resourceDeleteIcon {
    display: none;
}

.resourceLine:hover .resourceDeleteIcon {
    display: flex;
    position: relative;
    color: red;
    cursor: pointer;
    font-size: 17px;
}

.workItemRow .editIconSty {
    display: none;
}

.workItemRow .MuiIconButton-root {
    padding: 1px;
    margin-left: 2px;
}

.workItemRow .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
}


.workItemRow:hover .editIconSty {
    display: block;
    position: absolute;
    top: -2px;
    right: 55px;
}

.editIconSty .MuiSvgIcon-root {
    color: #2196f3
}

.workItemRow .MuiButton-root:hover {
    background-color: #2196f3;
}

.workItemRow .singleItemBtnCont {
    display: none;
}

.workItemRow:hover .singleItemBtnCont {
    position: absolute;
    top: 1px;
    right: 1px;
    display: flex;
    align-items: center;
    background-color: #2196f3;
    padding: 4px 10px;
    min-width: 35px;
    border-radius: 10px;
    margin-left: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}